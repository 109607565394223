/* eslint-disable react/jsx-props-no-spreading, react/jsx-no-duplicate-props */
import {
  Box,
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Field, FieldProps, Form, Formik, getIn } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  FormikErrorSnackbar,
  HealthIDCustom,
  RegistrationCustom,
  SinglePanelLayout,
} from '../../Components';
import useClinicConfig from '../../Hooks/useClinicConfig';
import {
  getLanguage,
  useExtendedTranslation,
} from '../../Services/i18nService';
import { ISurveyData } from '../../Types';
import { HEALTHID_REGEX, REGISTRATION_REGEX } from '../../Util/util';
import HealthCardEn from './HealthCard/manitoba-health-card-en.min.svg';
import HealthCardFr from './HealthCard/manitoba-health-card-fr.min.svg';

import { Lightbox } from '../../Components/Lightbox/Lightbox';
import InQStyleGuide from '../../constants';
import './ManitobaHealthCardForm.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      maxHeight: '30vh',
      padding: theme.spacing(0.25),
    },
    imagePreview: {
      maxWidth: '100%',
      borderRadius: theme.spacing(1),
      border: '0.125rem solid rgba(255, 255, 255, 0)',
      '&:hover': {
        border: `0.125rem solid ${InQStyleGuide.blue.medium}`,
        cursor: 'pointer',
      },
    },
  })
);

function ManitobaHealthCardForm({
  userData,
  onNext,
}: {
  onNext: Function;
  userData: ISurveyData;
}) {
  const lang = getLanguage();
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const classes = useStyles();

  const [openLightbox, setOpenLightbox] = useState(false);

  const validationSchema = Yup.object({
    healthIdNumber: Yup.string().when('hasNoHealthCard', {
      is: false,
      then: (s) =>
        s
          .matches(HEALTHID_REGEX, t('manitobahealthcardform.healthId.error'))
          .required(t('manitobahealthcardform.healthId.error')),
    }),
    registrationNumber: Yup.string().when('hasNoHealthCard', {
      is: false,
      then: (s) =>
        s
          .matches(
            REGISTRATION_REGEX,
            t('manitobahealthcardform.registration.error')
          )
          .required(t('manitobahealthcardform.registration.error')),
    }),
    dobDay: Yup.number().when('hasNoHealthCard', {
      is: false,
      then: (s) =>
        s
          .required(t('manitobahealthcardform.dob.day.error'))
          .min(1, t('manitobahealthcardform.dob.day.error'))
          .max(31, t('manitobahealthcardform.dob.day.error')),
    }),
    dobMonth: Yup.number().when('hasNoHealthCard', {
      is: false,
      then: (s) =>
        s
          .required(t('manitobahealthcardform.dob.month.error'))
          .min(1, t('manitobahealthcardform.dob.month.error'))
          .max(12, t('manitobahealthcardform.dob.month.error')),
    }),
    dobYear: Yup.number().when('hasNoHealthCard', {
      is: false,
      then: (s) =>
        s
          .required(t('manitobahealthcardform.dob.year.error'))
          .min(1900, t('manitobahealthcardform.dob.year.error'))
          .max(2100, t('manitobahealthcardform.dob.year.error')),
    }),
    hasNoHealthCard: Yup.boolean(),
  });

  return (
    <Box display="flex" flex={1} className="screen-manitoba-hc">
      <Formik
        initialValues={userData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let valuesToSubmit = values;

          if (values.hasNoHealthCard) {
            valuesToSubmit = {
              ...values,
              healthIdNumber: undefined,
              registrationNumber: undefined,
              dobDay: undefined,
              dobMonth: undefined,
              dobYear: undefined,
            };
          }

          onNext(valuesToSubmit);
        }}
      >
        {({ values, isSubmitting, isValidating, submitForm }) => {
          const { hasNoHealthCard } = values;

          return (
            <Form style={{ display: 'flex', flex: 1 }}>
              <FormikErrorSnackbar />
              <SinglePanelLayout
                footer={
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || isValidating}
                        onClick={submitForm}
                      >
                        {t('questionnaire.continue.button')}
                      </Button>
                    </Grid>
                  </Grid>
                }
                logo={clinicConfig.logoName}
              >
                <Typography>
                  {t('manitobahealthcardform.description')}
                </Typography>
                <Box
                  className={classes.imagePreview}
                  textAlign={'center'}
                  onClick={() => setOpenLightbox(true)}
                  role="button"
                  aria-label={t('manitobahealthcardform.card.zoom')}
                >
                  <img
                    src={lang === 'fr' ? HealthCardFr : HealthCardEn}
                    alt={t('manitobahealthcardform.example_card')}
                    className={classes.card}
                  />
                  <Typography>
                    {t('manitobahealthcardform.card.zoom')}
                  </Typography>
                </Box>
                {/* using ugly ternary to make compiler happy */}
                {hasNoHealthCard ? (
                  <></>
                ) : (
                  <>
                    <FormControl>
                      <FormLabel required={!hasNoHealthCard}>
                        {t('manitobahealthcardform.healthId')}
                      </FormLabel>
                      <Field name="healthIdNumber">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            InputProps={{
                              inputComponent: HealthIDCustom,
                            }}
                            placeholder={t(
                              'manitobahealthcardform.healthId.placeholder'
                            )}
                            {...field}
                          />
                        )}
                      </Field>
                    </FormControl>

                    <FormControl>
                      <FormLabel required={!hasNoHealthCard}>
                        {t('manitobahealthcardform.registration')}
                      </FormLabel>
                      <Field name="registrationNumber">
                        {({ form, field }: FieldProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={
                              getIn(form.errors, field.name) &&
                              getIn(form.touched, field.name)
                            }
                            InputProps={{
                              inputComponent: RegistrationCustom,
                            }}
                            placeholder={t(
                              'manitobahealthcardform.registration.placeholder'
                            )}
                            {...field}
                          />
                        )}
                      </Field>
                    </FormControl>

                    <FormControl style={{ marginBottom: '16px' }}>
                      <FormLabel required={!hasNoHealthCard}>
                        {t('manitobahealthcardform.dob')}
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControl>
                            <FormLabel>
                              {t('manitobahealthcardform.dob.day')}
                            </FormLabel>
                            <Field name="dobDay">
                              {({ form, field }: FieldProps) => (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  type="text"
                                  error={
                                    getIn(form.errors, field.name) &&
                                    getIn(form.touched, field.name)
                                  }
                                  placeholder={t(
                                    'manitobahealthcardform.dob.day.placeholder'
                                  )}
                                  inputProps={{
                                    maxLength: 2,
                                    inputMode: 'numeric',
                                  }}
                                  {...field}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl>
                            <FormLabel>
                              {t('manitobahealthcardform.dob.month')}
                            </FormLabel>
                            <Field name="dobMonth">
                              {({ form, field }: FieldProps) => (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  type="text"
                                  error={
                                    getIn(form.errors, field.name) &&
                                    getIn(form.touched, field.name)
                                  }
                                  placeholder={t(
                                    'manitobahealthcardform.dob.month.placeholder'
                                  )}
                                  inputProps={{
                                    maxLength: 2,
                                    inputMode: 'numeric',
                                  }}
                                  {...field}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl>
                            <FormLabel>
                              {t('manitobahealthcardform.dob.year')}
                            </FormLabel>
                            <Field name="dobYear">
                              {({ form, field }: FieldProps) => (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  type="text"
                                  error={
                                    getIn(form.errors, field.name) &&
                                    getIn(form.touched, field.name)
                                  }
                                  placeholder={t(
                                    'manitobahealthcardform.dob.year.placeholder'
                                  )}
                                  inputProps={{
                                    maxLength: 4,
                                    inputMode: 'numeric',
                                  }}
                                  {...field}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <Divider />
                  </>
                )}
                <FormControl>
                  <FormControlLabel
                    style={{ marginTop: '24px', marginLeft: 0 }}
                    control={
                      <Field name="hasNoHealthCard">
                        {({ field, form }: FieldProps) => (
                          <Switch
                            checked={field.value}
                            onChange={(
                              _event: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => form.setFieldValue(field.name, checked)}
                            color="primary"
                            size="small"
                            type="checkbox"
                          />
                        )}
                      </Field>
                    }
                    label={
                      <Typography className="label">
                        {t('manitobahealthcardform.nohealthcard')}
                      </Typography>
                    }
                  />
                </FormControl>
                <Lightbox
                  open={openLightbox}
                  handleClose={() => setOpenLightbox(false)}
                  alt={t('manitobahealthcardform.example_card')}
                  src={lang === 'fr' ? HealthCardFr : HealthCardEn}
                />
              </SinglePanelLayout>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default ManitobaHealthCardForm;
