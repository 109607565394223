import {
  alpha,
  createStyles,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useExtendedTranslation } from '../../Services/i18nService';
import InQStyleGuide from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    image: {
      width: '100%',
      maxHeight: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1rem',
    },
  })
);

export function Lightbox({
  open,
  handleClose,
  alt,
  src,
}: {
  open: boolean;
  handleClose: () => void;
  alt?: string;
  src?: string;
}) {
  const t = useExtendedTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: alpha(InQStyleGuide.white, 0.75),
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label={t('questionnaire.close')}
          className={classes.closeButton}
          onClick={handleClose}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <img
        src={src}
        alt={alt}
        className={classes.image}
        onClick={handleClose}
      />
    </Dialog>
  );
}
